<template>
  <div>
    <common-header></common-header>
    <!-- content start -->

    <!-- 表单 start -->
    <div class="person">
      <!-- {{da.avatar}}，{{da.wxImg}},========================= -->
      <div class="flex item">
        <span class="tit">头像</span>
        <div class="fe input-box">
          <img :src="getImgsrc(da.avatar, da.wxImg)" id="img" />
          <input
            class="picture"
            id="picture"
            type="file"
            accept="image/*"
            @change="choicePicture($event)"
          />
        </div>
      </div>
      <div class="flex item">
        <span class="tit">昵称</span>
        <div class="fe input-box">
          <input
            type="text"
            v-model="da.userNickName"
            placeholder="请输入昵称"
            @blur="modifyName"
            class="input"
          />
        </div>
      </div>
      <div class="flex item">
        <span class="tit">手机号码</span>
        <div class="fe input-box" @click="$router.push({path: '/account/updatephone'})">
          <input
            type="text"
            readonly
            v-model="da.phone"
            placeholder="请输入手机号码"
            class="input"
          />
        </div>
      </div>
      <div class="flex item">
        <span class="tit">密码</span>
        <div class="fe input-box" @click="$router.push({path: '/account/updatepwd'})">
          <input
            type="text"
            readonly
            placeholder="修改密码"
            class="input"
          />
        </div>
      </div>
      <div class="flex item">
        <span class="tit">性别</span>
        <div class="fe input-box" @click="genderPopup = true">
          <input
            type="text"
            readonly
            placeholder="请选择性别"
            :value="isGender(da.gender)"
            class="input"
          />
        </div>
      </div>
      <div class="flex item">
        <span class="tit">生日</span>
        <div class="fe input-box">
          <input class="input" type="text" readonly placeholder="请选择您的生日" v-model="da.birth" @click="openPicker">
        </div>
      </div>
      <!-- <div class="flex item" @click="$router.push({path: '/account/addressindex'})">
        <span class="tit">收货地址</span>
        <div class="fe input-box">已设置</div>
        <i class="icon-hui-arrow-right"></i>
      </div> -->
    </div>

    <!-- 表单 end -->

    <!-- 选择性别 -->
    <mt-popup class="w100" v-model="genderPopup" position="bottom">
      <div class="select-popup-btn fix">
        <span class="btn fl" @click="genderPopup = false">取消</span>
        <span class="btn fr" @click="modifyGender">确定</span>
      </div>
      <mt-picker
        :slots="genderSlots"
        @change="onGenderChange"
        value-key="text"
      ></mt-picker>
    </mt-popup>

    <!-- content end -->
    <!-- 出生日期弹窗 start -->
    <mt-datetime-picker
      ref="picker"
      type="date"
      year-format="{value} 年"
      month-format="{value} 月"
      date-format="{value} 日"
      v-model="startDate"
      :endDate="new Date()"
      :startDate="new Date('1921-07-23')"
      @confirm="dateConfirm">
    </mt-datetime-picker>
    <!-- 出生日期弹窗 end -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { requestImgUrl, isGender } from "@/utils/common";
import { formatDate } from "@/utils/utils";
import MtPick from "@/components/modules/MtPick";
import avatar from "@/assets/images/img/default-headimg.jpg";
export default {
  name: "Person",
  data() {
    this.requestImgUrl = url => requestImgUrl(url);
    this.isGender = url => isGender(url);
    this.formatDate = (v) => formatDate(v)
    return {
      // 选择性别弹框
      genderPopup: false,
      startDate: new Date(),
      genderSlots: [
        {
          flex: 1,
          values: [
            {
              val: 1,
              text: "男"
            },
            {
              val: 2,
              text: "女"
            }
          ],
          className: "genderSlots",
          textAlign: "center"
        }
      ],
      selectGenderValue: "",
      showLinkage: false,
      da: {}
    };
  },
  components: {
    MtPick
  },
  mounted() {
    // 用户信息
    this.getUserInfo().then(res => {
      this.da = res.data;
    });
  },
  methods: {
    // 时间确认
    dateConfirm(e) {
      this.da.birth = this.formatDate(e);
      this.modifyUserInfo({
        birth: this.formatDate(e)
      }).then(() => {
        this.$toast("生日修改成功");
      });
    },
    // 出生日期
    openPicker() {
      this.$refs.picker.open();
    },
    ...mapActions("account", ["getUserInfo", "modifyUserInfo"]),
    // 图片获取
    getImgsrc(v1, v2) {
      // console.log(v1, v2);
      if (v1 && v1 !== "") {
        return (
          requestImgUrl(v1) + "?x-oss-process=image/resize,m_fill,h_140,w_140"
        );
      } else if (v2) {
        return v2;
      } else {
        return avatar;
      }
    },
    // 头像剪切
    imgcb(v, cb) {
      this.modifyUserInfo({ avatar: v.fileUri }).then(res => {
        this.da.avatar = res.data.avatar;
        cb();
      });
    },
    choicePicture(event) {
      const image = document.getElementById("img");
      this.clip(event, {
        resultObj: image,
        aspectRatio: 1
      });
    },
    // 性别
    onGenderChange(picker, values) {
      this.selectGenderValue = values[0];
    },
    modifyGender() {
      if (this.selectGenderValue === "") {
        return;
      }
      this.da.gender = this.selectGenderValue.val;
      this.genderPopup = false;
      this.modifyUserInfo({
        gender: this.da.gender
      }).then(() => {
        this.$toast("性别修改成功");
      });
    },
    // 昵称
    modifyName() {
      if (!this.da.userNickName || this.da.userNickName === "") {
        return;
      }
      this.modifyUserInfo({
        userNickName: this.da.userNickName
      }).then(() => {
        this.$toast("昵称修改成功");
      });
    },
    modifyPhone() {
      if (!this.da.phone || this.da.phone === "") {
        return;
      }
      this.modifyUserInfo({
        phone: this.da.phone
      }).then(() => {
        this.$toast("手机号码修改成功");
      });
    },
    // 个性签名
    modifyProfile() {
      if (!this.da.profile || this.da.profile === "") {
        return;
      }
      this.modifyUserInfo({
        profile: this.da.profile
      }).then(() => {
        this.$toast("个性签名修改成功");
      });
    },
    // 城市接收子组件数据
    showChildMsg(v) {
      const regions = `${v[0].id}-${v[1].id}-${v[2].id}`;
      const name = `${v[0].name}-${v[1].name}-${v[2].name}`;
      this.da.selectRegion = name;
      this.modifyUserInfo({ selectRegion: name });
      this.modifyUserInfo({ selectRegionId: regions });
    },
    // 关闭城市弹窗
    closeHandle() {
      this.showLinkage = false;
    },
    // 邮箱
    modifyEmail() {
      if (!isEmail(this.da.userEmail)) {
        this.$toast("邮箱格式不正确");
        return;
      }

      this.modifyUserInfo({
        userEmail: this.da.userEmail
      }).then(() => {
        this.$toast("邮箱修改成功");
      });
    }
  }
};
</script>
<style lang="less" scoped>
.icon-hui-arrow-right{
  margin-left: .2rem;
}
.person {
  padding: 0.2rem .4rem;
  .item {
    position: relative;
    align-items: center;
    border-bottom: 1px solid #ececec;
  }

  .tit {
    color: #565656;
    font-size: 0.3rem;
  }

  .input-box {
    height: 1.1rem;
    position: relative;
    text-align: right;
    line-height: 1.1rem;
    font-size: 0.32rem;
  }
  .input {
    text-align: right;
    font-size: 0.32rem;
    color: #262626;
    width: 100%;
    line-height: 0.6rem;
    height: 0.6rem;
  }
  #img {
    border-radius: 50%;
    width: 0.7rem;
    height: 0.7rem;
  }
  #picture {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
